import { render, staticRenderFns } from "./NoGatesFound.vue?vue&type=template&id=2d660ecd&scoped=true"
import script from "./NoGatesFound.vue?vue&type=script&lang=js"
export * from "./NoGatesFound.vue?vue&type=script&lang=js"
import style0 from "./NoGatesFound.vue?vue&type=style&index=0&id=2d660ecd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d660ecd",
  null
  
)

export default component.exports