<template>
  <v-dialog
    v-model="showModal"
    max-width="500"
    persistent
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-tablet-cellphone
        </v-icon> Edit Kiosk
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text class="text-body-1 text-center">
            <v-text-field
              v-model="name"
              class="field-style"
              :rules="[(v) => !!v || 'This field is required']"
              label="Name"
              outlined
              dense
            />
            <br>
            <v-text-field
              v-model="location"
              class="field-style"
              :rules="[(v) => !!v || 'This field is required']"
              label="Location"
              outlined
              dense
            />
            <br>
            <v-text-field
              v-model="password"
              class="field-style"
              :rules="[(v) => !!v || 'This field is required']"
              label="Password"
              outlined
              dense
            />
            <br>
            <v-select
              ref="workerCheck"
              v-model="status"
              class="field-style"
              :items="statusList"
              item-text="name"
              item-value="id"
              label="Status"
              :rules="[(v) => !!v || 'This field is required']"
              single-line
              persistent-hint
              outlined
              dense
            />
            <br>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              @click="updateDevice()"
            >
              Update
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner :loading="loading" />
  </v-dialog>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import Constants from 'src/constants';
export default {
  name: 'CreateDevice',
  components: {
    'centre-spinner': spinner,
  },
  props: {
    selectedDevice: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      showModal: true,
      name: '',
      data: {},
      password: '',
      location: '',
      isFormValid: false,
      loading: false,
      status: null,
      statusList: [
        {
          name: 'Active',
          id: 'active',
        },
        {
          name: 'InActive',
          id: 'inactive',
        },
      ],
    };
  },
  computed: {
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  async mounted () {
    this.loading = true;
    await this.loadOnMount();
    this.loading = false;
  },
  methods: {
    async loadOnMount () {
      this.name = this.selectedDevice.name;
      this.password = this.selectedDevice.password;
      this.status = this.selectedDevice.status;
      this.location = this.selectedDevice.location_name;
    },
    async updateDevice () {
      if (this.$refs.form.validate() === false) {
        this.$store.dispatch('alert/onAlert', {
          message: 'Please fill the required fields before proceeding.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        return;
      }
      this.loading = true;
      if (this.name === this.selectedDevice.name) {
         this.data = {
          location_name: this.location,
          password: this.password,
          status: this.status,
        };
      } else {
         this.data = {
          name: this.name,
          location_name: this.location,
          password: this.password,
          status: this.status,
        };
      }
      await this.$store.dispatch('devices/updateDevice', {
        id: this.selectedDevice.id,
        data: this.data,

      }).then((response) => {
        this.$emit('success');
        this.$store.dispatch('alert/onAlert', {
          message: 'Device updated successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
        this.loading = false;
        this.showModal = false;
      })
        .catch(() => {
          this.loading = false;
          this.showModal = false;
        });
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
  margin-bottom: 10px;
}

.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}

.my-container-style {
  padding-top: 24px;
}

.hrw-switch {
  margin-top: -20px;
}

.field-style {
  margin-top: -10px;
}
</style>
