<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <h2 class="title-style pt-3">
      Project Gates 🚧
    </h2>
    <p class="mb-0 mt-0 sub-title-style">
      Explore Project gates and Kiosks here.
    </p>
    <v-tabs
      v-model="tabsProjectGate"
      class="mt-5"
    >
      <v-tab>
        <v-icon class="mr-2">
          mdi-boom-gate-outline
        </v-icon>Gates
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">
          mdi-tablet-cellphone
        </v-icon>Kiosks
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabsProjectGate">
      <v-tab-item>
        <project-gates-list />
      </v-tab-item>
      <v-tab-item>
        <device-lists />
      </v-tab-item>
    </v-tabs-items>
    <no-gates-found v-if="showNoGatesFound" />
  </v-container>
</template>
<script>
  import DeviceList from './devices/KiosksList';
  import global from 'src/mixins/global';
  import ProjectGatesList from './ProjectGatesList.vue';
  import ModuleHelper from 'src/helpers/module-helper';
  import Constants from 'src/constants';
  import NoGatesFound from './NoGatesFound.vue';
  export default {
    name: 'ProjectGatesList',
    components: {
      'device-lists': DeviceList,
      'project-gates-list': ProjectGatesList,
      'no-gates-found': NoGatesFound,

    },
    mixins: [global],
    data () {
      return {
        deleteId: '',
        loading: false,
        projectGateId: '',
        selectedProject: '',
        showDeviceList: false,
        showGatesList: false,
        showNoGatesFound: false,
        helper: new ModuleHelper(),
        selectedItem: -1,
        tabsProjectGate: null,
      };
    },
    computed: {
        isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
      isKioskModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECT_GATES, Constants.SUB_MODULE_KIOSK));
      },
      isProjectGateModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECT_GATES, Constants.SUB_MODULE_PROJECT_GATES));
      },
    },
    async mounted () {
      this.loading = true;
      setTimeout(() => {
        this.checkPreRoute();
        this.loading = false;
      }, 1000);

      this.loading = false;
    },
    methods: {
      checkPreRoute () {
       if (this.$router.history.current.meta.previousRoute.name === 'Kiosk Check-Ins') {
        this.selectedItem = 1;
        this.showGatesList = false;
        this.showDeviceList = true;
       } else {
        this.ifGates();
       }
      },
        ifGates () {
            if (this.isProjectGateModuleExist) {
              this.selectedItem = 0;
              this.showDeviceList = false;
              this.showGatesList = true;
            } else if (this.isKioskModuleExist) {
              this.selectedItem = 1;
              this.showGatesList = false;
              this.showDeviceList = true;
            } else {
              this.showDeviceList = false;
              this.showGatesList = false;
              this.showNoGatesFound = true;
            }
        },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.premium-style {
  font-size:25px;
  color:#006883!important;
  font-weight:bold;
}
.premium-container{
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left:0;
}
.premium{
  position: relative;
}
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.title-style{
  font-size: 36px;
  font-weight: 600;
}
.sub-title-style{
  color: #aaa;
}
</style>
