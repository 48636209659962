<template>
  <div>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <v-col class="text-right table-top-border">
        <v-btn
          color="#000"
          @click="openCreateForm()"
        >
          <v-icon class="mr-1">
            mdi-plus-circle-outline
          </v-icon>
          Add Kiosk
        </v-btn>
      </v-col>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
          <v-spacer />
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="devicesList"
        :search="search"
        class="table-projects"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <span class="info-container">
                {{ item.name }}
              </span>
            </td>
            <td>
              <span class="info-container">
                {{ item.location_name }}
              </span>
            </td>
            <td>
              <span class="info-container">
                {{ item.password }}
              </span>
            </td>
            <td>
              <span class="info-container">
                {{ item.created_at | convertToLocal }}
              </span>
            </td>
            <td>
              <font
                :color="getColor(item.status)"
                class="font-my-style"
              >
                {{ item.status | statusUpdate }}
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click.stop
                @click="openCheckIns(item.id)"
              >
                <v-icon>mdi-map-marker-account</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="editDevice(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                :disabled="item.database === false"
                icon
                color="#C62828"
                @click.stop
                @click="deleteDevice(item.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner :loading="loading" />
    <create-device
      v-if="showModel"
      @success="deviceCreated"
      @closed="showModel = false"
    />
    <delete-app-user
      v-if="showDelete"
      :title="'Delete this Kiosk ?'"
      :message="'Action will permanently delete the Kiosk ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <edit-device
      v-if="showEditForm"
      :selected-device="selectedDevice"
      @closed="showEditForm = false"
      @success="fetchDevicesList()"
    />
  </div>
</template>
<script>
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import CreateDevice from './CreateDevice.vue';
import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
import EditDevice from './EditDevice.vue';
import moment from 'moment';
export default {
  name: 'DevicesList',
  components: {
    'centre-spinner': spinner,
    'create-device': CreateDevice,
    'delete-app-user': DeleteDialog,
    'edit-device': EditDevice,
  },
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
    statusUpdate (status) {
      if (status === 'inactive') {
        return 'Inactive';
      } else {
        return 'Active';
      }
    },
  },
  data () {
    return {
      loading: false,
      headers: [
        { text: 'Kiosk Name', align: 'start', value: 'name' },
        { text: 'Location', align: 'start', value: 'location_name' },
        { text: 'Password ', value: 'password' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Status', value: 'status' },
        { text: 'Check-Ins', value: 'check-ins' },
        { text: 'Edit', value: 'edit' },
        { text: 'Delete', value: 'delete' },
      ],
      name: null,
      password: null,
      showModel: false,
      search: '',
      showDelete: false,
      deleteId: null,
      selectedDevice: null,
      showEditForm: false,
    };
  },
  computed: {
    devicesList () {
      return this.$store.getters['devices/getDevicesList'];
    },
  },
  async mounted () {
    this.loading = true;
    await this.fetchDevicesList();
    this.loading = false;
  },
  methods: {
    openCreateForm () {
      this.showModel = true;
    },
    async fetchDevicesList () {
      this.loading = true;
      await this.$store.dispatch('devices/fetchDevicesList');
      this.loading = false;
    },
    deviceCreated () {
      this.loading = true;
      this.fetchDevicesList();
      this.loading = false;
    },
    async deleteDevice (id) {
      this.showDelete = true;
      this.deleteId = id;
    },
    async deleteConfirmed () {
      this.showDelete = false;
      this.loading = true;
      await this.$store.dispatch('devices/deleteDevice', { id: this.deleteId }).then((response) => {
        this.$store.dispatch('alert/onAlert', {
          message: 'Kiosk deleted successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        })
          .catch(() => {
            this.loading = false;
          });
        this.loading = false;
        this.fetchDevicesList();
      });
      this.showDelete = false;
      this.loading = false;
    },
    editDevice (item) {
      this.selectedDevice = item;
      this.showEditForm = true;
    },
    getColor (val) {
      if (val === 'inactive') {
        return '#C62828';
      } else {
        return '#2E7D32';
      }
    },
    async openCheckIns (id) {
      this.$router.push(`/project-gates/kiosk/${id}/check-ins`);
    },
  },
};
</script>
<style scoped>
.icon-style {
  color: #37474F;
  font-size: 45px;
}

.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}

.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}

.v-data-table {
  background-color: #ECEFF1;
}

.title-style {
  color: #37474F;
}
.font-my-style {
  text-transform: capitalize;
  font-weight: bold;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.v-card {
  margin-top: 0px !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
}
</style>
