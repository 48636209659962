<template>
  <div>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <v-col
        class="text-right table-top-border"
      >
        <v-btn
          color="#37474F"
          class="mr-2"
          @click="$router.push({ name: 'Group Project Gates' })"
        >
          Group Gates
        </v-btn>
        <v-btn
          color="#000"
          @click="toCreateProjectGate()"
        >
          <v-icon class="mr-1">
            mdi-plus-circle-outline
          </v-icon>
          New Gate
        </v-btn>
      </v-col>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            lg="4"
            md="2"
            sm="1"
            class="text-right"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  dark
                  outlined
                  color="#37474F"
                  small
                  v-on="on"
                  @click="getProjectGatesList()"
                >
                  <v-icon
                    small
                    dark
                  >
                    mdi-filter-off
                  </v-icon>
                </v-btn>
              </template>
              <span>All Gates</span>
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="11"
          >
            <v-autocomplete
              v-model="selectedProject"
              :items="projectsList"
              item-text="name"
              item-value="id"
              label="Select Project*"
              single-line
              append-icon="mdi-file-sign"
              outlined
              dense
              @input="getProjectGatesByProject()"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="projectGatesList"
        :search="search"
        class="elevation-1 table-projects"
        disable-sort
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.name }}</td>
            <td>{{ item.project.name }}</td>
            <td>{{ item.timezone | timeZoneByName }}</td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                color="#37474F"
                x-small
                icon
                @click="showGateCheckIns(item.id)"
              >
                <v-icon>mdi-map-marker-account</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click="downloadQrCode(item.project.name, item.id, item.name)"
              >
                <v-icon>mdi-qrcode</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="editProjectGateDetails(item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click="deleteProjectGate(item.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <delete-project-gate
      v-if="showDelete"
      :title="'Delete this project gate ?'"
      :message="'Action will permanently remove the project gate from the list, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import QRCode from 'qrcode';
  import { scipAppDomainUrl } from 'src/app-globals';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import Constants from 'src/constants';
  import global from 'src/mixins/global';
  export default {
    name: 'ProjectGatesList',
    components: {
      'centre-spinner': spinner,
      'delete-project-gate': DeleteDialog,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      timeZoneByName (val) {
       return val.slice(val.indexOf(' ') + 1);
      },
    },
    mixins: [global],
    data () {
      return {
        showDelete: false,
        showForm: false,
        deleteId: '',
        headers: [
          { text: 'Gate Name', align: 'start', value: 'name' },
          { text: 'Project', value: 'project.name' },
          { text: 'Time Zone', value: 'location_timezone' },
          { text: 'Created At', value: 'created_at' },
          { text: 'Check-Ins', value: 'check-ins' },
          { text: 'QR Code', value: 'qrcode' },
          { text: 'Edit', value: 'edit' },
          { text: 'Delete', value: 'delete' },
        ],
        loading: false,
        search: '',
        projectGateId: '',
        selectedProject: '',
      };
    },
    computed: {
      projectGatesList () {
        return this.$store.getters['projectgates/getProjectGatesList'];
      },
      projectsList () {
        return this.$store.getters['projects/getProjectsList'];
      },
    },
    async mounted () {
      this.loading = true;
      await this.getProjectGatesList();
      await this.getProjectsList();
      this.loading = false;
    },
    methods: {
      async getProjectGatesList () {
        this.loading = true;
        this.selectedProject = '';
        await this.$store.dispatch('projectgates/fetchProjectGatesList').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      async getProjectsList () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectsList').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      showGateCheckIns (projectGateId) {
        this.$router.push(`/project-gates/${projectGateId}/check-ins`);
      },
      async downloadQrCode (projectName, projectGateId, projectGateName) {
        this.loading = true;
        QRCode.toDataURL(`${scipAppDomainUrl}/project-gates/${projectGateId}`, { errorCorrectionLevel: 'H' })
        .then(url => {
          var a = document.createElement('a');
          a.href = url;
          a.download = `${projectName}-${projectGateName}.png`;
          a.click();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      },
      deleteProjectGate (id) {
        this.projectGateId = id;
        this.showDelete = true;
      },
      async deleteConfirmed () {
        this.loading = true;
        this.showDelete = false;
        await this.$store.dispatch('projectgates/deleteProjectGate', {
          projectGateId: this.projectGateId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project gate deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.showDelete = false;
          this.loading = false;
          this.getProjectGatesList();
        }).catch(() => {
          this.loading = false;
        });
      },
      editProjectGateDetails (projectGateId) {
        this.$store.dispatch('projectgates/removeSavedProjectGateDetails');
        this.$store.dispatch('projectgates/removeMarkedProjectGateDetails');
        this.$router.push(`/project-gates/${projectGateId}/edit`);
      },
      toCreateProjectGate () {
        this.$store.dispatch('projectgates/removeSavedProjectGateDetails');
        this.$store.dispatch('projectgates/removeMarkedProjectGateDetails');
        this.$router.push({ name: 'Add Project Gate' });
      },
      async getProjectGatesByProject () {
        this.loading = true;
        await this.$store.dispatch('projectgates/fetchProjectGatesByProject', {
          params: {
            projectId: this.selectedProject,
          },
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.premium-style {
  font-size:25px;
  color:#006883!important;
  font-weight:bold;
}
.premium-container{
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left:0;
}
.premium{
  position: relative;
}
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.mdi-office-building-marker {
  color: #37474F;
  font-size: 45px;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.v-card {
  margin-top: 0px !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
}
</style>
